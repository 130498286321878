
import { SortType } from '@/enums/sort-type.enum';
import { SortEntry } from '@/interfaces/sort-entry.interface';
import { SortActions } from '@/store/sort/actions.enum';
import isAsc from '@/utils/is-asc-sort';
// eslint-disable-next-line import/order
import Vue from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SortSelector',
  data() {
    return {
      items: [{
        text: `${this.$t('sort.header_asc')}`,
        value: `${this.$t('sort.header_asc')}`,
        header: `${this.$t('sort.header_asc')}`,
      },
      {
        text: `${this.$t('sort.default')}`,
        value: SortType.CMS_ASC,
      },
      {
        text: this.$t('sort.date'),
        value: SortType.DATE_ASC,
      },
      {
        text: this.$t('sort.title'),
        value: SortType.TITLE_ASC,
      },
      {
        text: `${this.$t('sort.header_desc')}`,
        value: `${this.$t('sort.header_desc')}`,
        header: `${this.$t('sort.header_desc')}`,
      },
      {
        text: `${this.$t('sort.default')}`,
        value: SortType.CMS_DESC,
      },
      {
        text: this.$t('sort.date'),
        value: SortType.DATE_DESC,
      },
      {
        text: this.$t('sort.title'),
        value: SortType.TITLE_DESC,
      }] as SortEntry[],
    };
  },
  computed: {
    ...mapGetters('sort', [
      'type',
    ]),
    selectedSort: {
      get(): SortType {
        return this.type;
      },
      set(val: SortType) {
        this.setSortType(val);
      },
    },
    isAscSort(): boolean {
      return isAsc(this.type);
    },
  },
  methods: {
    ...mapActions('sort', {
      setSortType: SortActions.SET_TYPE,
    }),
    focusSelect() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const select = this.$refs.select as any;

      if (select) {
        select.focus();
        select.activateMenu();
      }
    },
  },
});


import ReloadButton from '@/components/BaseReloadButton.vue';
import Grid from '@/components/Grid.vue';
import GridFileItem from '@/components/GridFileItem.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SortSelector from '@/components/SortSelector.vue';
import OpenFileMixin from '@/mixins/open-file';
import { FavoriteActions } from '@/store/favorite/actions.enum';
// eslint-disable-next-line import/order
import Vue from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Favorites',

  mixins: [OpenFileMixin],

  components: {
    ReloadButton,
    Grid,
    GridFileItem,
    LoadingSpinner,
    SortSelector,
  },

  computed: {
    ...mapGetters('favorite', ['items', 'loading']),
  },

  methods: {
    ...mapActions('favorite', { fetchFavorites: FavoriteActions.GET_ALL }),

    async reload() {
      await this.fetchFavorites();
    },
  },
});
